<template>
  <div class="shortcuts-section">
    <a-card>
      <template #title>
        <div class="mt-1">
          <div class="d-flex font-size-20">
            <span v-if="sectionTitle" class="section-title" :class="{ 'hidden-title': !showLabel }" >
              {{ sectionTitle }}
            </span>
          </div>
        </div>
      </template>
      <template #extra>
        <div class="d-flex align-items-center section-extra font-size-20 text-right">
          <div class="mt-0 d-flex align-items-center">
            <span>
              <a-button :disabled="!isEditAvailable" type="primary" icon="plus" @click="createLinkModal">
                Add
              </a-button>
            </span>
            <div class="mr-auto ml-3" style="margin-top: 0.1rem">
              <a-tooltip v-if="isEditAvailable" title="Edit section" placement="bottom">
                <a-icon @click="editSection" type="edit" class="main-tabs__sections__icons text-primary" />
              </a-tooltip>
            </div>
          </div>
          <div class="mr-auto ml-3">
            <span class="">
              <a-tooltip v-if="index < maxIndex - 1 && isEditAvailable" title="Move down" placement="bottom">
                <a-icon @click="moveDown" type="down" class="movable move-down text-primary" />
              </a-tooltip>
              <a-icon v-else type="down" class="movable text-gray-5" />
            </span>
            <span class="ml-3">
              <a-tooltip v-if="index > 0 && isEditAvailable" title="Move up" placement="bottom">
                <a-icon @click="moveUp" type="up" class="movable move-up text-primary" />
              </a-tooltip>
              <a-icon v-else type="up" class="movable text-gray-5" />
            </span>
          </div>
          <a href="javascript:" class="text-primary ml-3" @click.stop="changeStatusSection">
            <a-switch v-model="isActiveSectionStatus" class="ml-3" :disabled="!isEditAvailable" />
          </a>
          <div v-if="isEditAvailable" @click="toggleCarousel" class="ml-3 dropDown-icon" :class="{ 'spin': showAsList }">
            <DropDownIcon />
          </div>
        </div>
      </template>
      <template #cover>
        <transition-group v-if="localizedShortcuts.length" tag="div" class="carousel-container shortcuts-carousel"
          type="transition" name="flip-grid">
          <a-carousel v-if="!showAsList" arrows :slidesToShow="carouselToShow" :slidesToScroll="1" :dots="false" :infinite="false"
            :key="1" variableWidth :adaptiveHeight="false">
            <div style="z-index: 1" slot="prevArrow"><a-icon type="left" class="text-primary"></a-icon></div>
            <div style="z-index: 1" slot="nextArrow"><a-icon type="right" class="text-primary"></a-icon></div>
            <div v-for="(shortcutItem) in localizedShortcuts" :key="shortcutItem.id" :style="{
              width: shortcutType === 'shortcut_small' ? '75px' :
                shortcutType === 'shortcut_medium' ? '115px' :
                  shortcutType === 'shortcut_large' ? '295px' : '295px'
            }">
              <div v-if="shortcutItem.img_path" class="promo-item mr-3" :class="{'disabled': !shortcutItem.is_active}" @click="editShortcut(shortcutItem)">
                <img :src="shortcutItem.img_path" :class="linkCardType" />
                <a-tooltip :title="shortcutItem.title" placement="top">
                  <span class="promo-title">
                    {{ shortcutItem.title.length > 12 ? shortcutItem.title.slice(0, 12) + '...' : shortcutItem.title }}
                  </span>
                </a-tooltip>
              </div>
            </div>
          </a-carousel>
          <draggable v-else v-model="shortcutItems" v-bind="dragOptions" @start="drag = true" @end="sortShortcuts"
            tag="div" :key="2">
            <transition-group tag="div">
              <div v-for="(shortcutItem, index) in localizedShortcuts" :key="index" class="d-inline-block"
                style="margin: 0 1rem 1rem 0;">
                <div v-if="shortcutItem.img_path" class="promo-item transform-on-hover" :class="{'disabled': !shortcutItem.is_active}" @click="editShortcut(shortcutItem)">
                  <img :class="linkCardType" class="" :src="shortcutItem.img_path" />
                  <a-tooltip :title="shortcutItem.title" placement="top">
                    <span class="promo-title">
                      {{ shortcutItem.title.length > 12 ? shortcutItem.title.slice(0, 12) + '...' : shortcutItem.title}}
                    </span>
                  </a-tooltip>
                </div>
              </div>
            </transition-group>
          </draggable>
        </transition-group>
      </template>
    </a-card>
    <a-modal v-model="linkModal" :title="linkObj && linkObj.id ? `Edit link` : 'Add link'" :footer="null"
      class="link-modal" width="380px" :destroyOnClose="true" @cancel="closeLinkModal">
      <LinkModal @createLink="handleCreateLink" @updateLink="handleUpdateLink" @deleteLink="handleDeleteLink"
        @closeModalFunction="closeLinkModal" :link-obj="linkObj" :section-id="sectionId" :shortcutType="shortcutType"
        ref="linkModal" />
    </a-modal>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import DropDownIcon from '@/components/custom/icons/dropDownIcon.vue'
import LinkModal from '@/views/apps/viewscreens/modals/linkModal.vue'
import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  name: 'shortcutSection',
  props: ['shortcutSectionData', 'index', 'maxIndex', 'selectedLang', 'langs', 'showLabel', 'isActive', 'localizations', 'sectionId', 'shortcutType', 'isEditAvailable'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    draggable,
    DropDownIcon,
    LinkModal,
  },
  data: () => ({
    linkModal: false,
    linkObj: undefined,
    isActiveSectionStatus: false,
    shortcutItems: null,
    drag: false,
    publishLoading: false,
    showAsList: false,
    windowWidth: window.innerWidth,
  }),
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
    shortcutSectionData(value) {
      this.shortcutItems = value
    },
  },
  mounted() {
    this.shortcutItems = this.shortcutSectionData
    this.isActiveSectionStatus = this.isActive
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    linkCardType() {
      return (() => {
        switch (this.shortcutType) {
          case 'shortcut_small': return 'shortcut-small-img'
          case 'shortcut_medium': return 'shortcut-medium-img'
          case 'shortcut_large': return 'shortcut-large-img'
          default: return 'shortcut-large-img'
        }
      })()
    },
    carouselStyle() {
      return {
        width: this.carouselStyle(),
      }
    },
    getcarouselStyle() {
      return (() => {
        switch (this.shortcutType) {
          case 'shortcut_small': return '75px'
          case 'shortcut_medium': return '115px'
          case 'shortcut_large': return '295px'
          default: return '295px'
        }
      })()
    },
    selectedLangId() {
      return this.langs.find(lang => this.selectedLang === lang.shortcut).value
    },
    sectionTitle() {
      const localization = this.localizations.find(section => Number(section.lang_id) === Number(this.selectedLangId))
      return localization ? localization.value : null
    },
    localizedShortcuts() {
      if (!Array.isArray(this.shortcutItems)) {
        return []
      }
      return this.shortcutItems
        .filter(item => item)
        .map(item => {
          const localization = item.localizations.find(localization => {
            return localization.lang_id === Number(this.selectedLangId)
          })
          return {
            ...item,
            title: localization ? localization.title : '',
          }
        })
    },
    titleLength() {
      return (() => {
        switch (this.shortcutType) {
          case 'shortcut_small': return 10
          case 'shortcut_medium': return 25
          case 'shortcut_large': return 40
          default: return 40
        }
      })()
    },
    carouselToShow() {
      return (() => {
        switch (this.shortcutType) {
          case 'shortcut_small':
            switch (true) {
              case this.windowWidth >= 2000: return 25
              case this.windowWidth >= 1910: return 20
              case this.windowWidth <= 1200: return 14
              default: return 12
            }
          case 'shortcut_medium':
            switch (true) {
              case this.windowWidth >= 2000: return 16
              case this.windowWidth >= 1900: return 13
              case this.windowWidth >= 1200: return 12
              case this.windowWidth <= 1200: return 7
              default: return 8
            }
          case 'shortcut_large':
            switch (true) {
              case this.windowWidth >= 2000: return 5
              case this.windowWidth >= 1910: return 4
              case this.windowWidth >= 1550: return 4
              case this.windowWidth >= 1200: return 3
              case this.windowWidth <= 1200: return 2
              default: return 2
            }
          default:
            return 6
        }
      })()
    },
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    toggleCarousel() {
      this.showAsList = !this.showAsList
    },
    editSection() {
      this.$emit('editSection', this.sectionId)
    },
    editShortcut(shortcutItem) {
      if (!this.isEditAvailable) {
        return
      }
      this.linkObj = JSON.parse(JSON.stringify(shortcutItem))
      this.linkModal = true
    },
    moveDown() {
      this.$emit('moveDownSection', this.sectionId, 1)
    },
    moveUp() {
      this.$emit('moveUpSection', this.sectionId, -1)
    },
    changeStatusSection() {
      this.$emit('changeStatusSection', this.sectionId, this.isActiveSectionStatus)
    },
    createLinkModal() {
      this.linkObj = null
      this.linkModal = true
    },
    closeLinkModal() {
      this.linkModal = false
    },
    async handleCreateLink(created) {
      this.closeLinkModal()
      await this.addShortcutItem(created.id)
      this.shortcutItems.unshift(created)
      this.$emit('toggleIsPublished')
    },
    handleUpdateLink(updatedLink) {
      const indexUpdated = this.shortcutItems.findIndex(item => item.id === updatedLink.id)
      this.shortcutItems.splice(indexUpdated, 1, updatedLink)
      this.closeLinkModal()
      this.$emit('toggleIsPublished')
    },
    handleDeleteLink(deletedId) {
      this.shortcutItems.splice(this.shortcutItems.findIndex(item => item.id === deletedId), 1)
      this.closeLinkModal()
      this.$emit('toggleIsPublished')
    },
    async addShortcutItem(shortcutId) {
      this.loading = true
      try {
        const response = await ViewscreenService.addViewscreenSectionItem(this.sectionId, shortcutId)
        if (response.success) {
          this.$notification.success({
            message: 'Shortcut has been successfully added to the viewscreen section.',
          })
        }
        this.$emit('toggleIsPublished')
      } catch (e) {
        this.$notification.error({
          message: e.response.data.error.message,
        })
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async sortShortcuts() {
      const sortShortcuts = this.shortcutItems.map((shortcut, index, shortcutArr) => {
        return {
          id: shortcut.id,
          sort: shortcutArr.length - index,
        }
      })
      const sort = { sort: sortShortcuts }
      try {
        await ViewscreenService.sortSectionItems(this.sectionId, sort)
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
        this.$emit('toggleIsPublished')
      } catch (error) {
        console.log(error)
      }
      this.drag = false
    },
  },
}
</script>
<style lang="scss" src="./style.module.scss" />
